*{
    margin: 0;
    padding: 0;
}
.main__about{
    margin: 150px 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
}
.wrap-label{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
}
.col-md-6 {
    margin-left: 100px;
}
@media (max-width:642px) {
    .col-md-6 {
        margin-left: 10px;
    }

}