*{
    margin: 0;
    padding: 0;
}
.footer{
    padding-block: 5rem;
    background-color: #291e04;
    color: #666873;
    position: relative;
    z-index: 1000;
}

.footer-link{
    text-decoration: none;
    color: inherit;
    transition: color 0.25s;
}

.footer-hr{
    margin-block: 2.5rem;
    border: none;
    border-top: 1px solid rgba(252, 255, 255, 0.1);
}
.top-footer-wrap{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    row-gap: 2rem;
}
.footer-link:hover{
    color: #fff;
}
.title-footer{
    font-size: 1.8rem;
    color: #fff;
    font-weight: 500;
    margin-bottom: 2rem;
}

.bottom-footer {
    flex-direction: row;
}
.footer-list{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.footer-bottom{
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    flex-wrap: wrap;
}
.footer-wraper{
    max-width: 116rem;
    margin: auto;
    padding: 0 1.5rem;
    overflow: hidden;
}