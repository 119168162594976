*{
    margin: 0;
    padding: 0;
}
.Header__wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: start;
    justify-content: center;

}
.Header{
    background: #ffffff;
    width: 100%;
    height: 75px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.15));
}
.Header__logo a img{
    width: 75px;
    height: 75px;
}
.wrap__nav__items{
    display: flex;
    column-gap: 20px;
}
.nav__item{
    color: black;
    text-decoration: none;
    padding: 15px;
    font-family: "Mulish", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    transition: 0.3s;
    border-radius: 0.1px;
    font-size: 0.95em;
}
.Header__logo a{
    display: flex;
    justify-content: center;
    align-items: center;
}
.Header__logo a{
    font-size: 1.3em;
    color: black;
    text-decoration: none;
    padding: 15px;
    font-family: "Mulish", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    transition: 0.3s;
    border-radius: 0.1px;
}
.nav__item:hover{
    background-color: #dad9d9;
    border-radius: 1rem;
    color: black;
}
/*todo:*/
navi__item__icon img{
    transform: scale(10%);
}


.wrap__navbar__onPhone{
    position: fixed;
    width: 250px;
    height: 400px;
    background: #ffffff;
    right: 20px;
    border-radius: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.09);
    top: 20px;
    justify-content: space-around;
    transition: 0.3s;
    display: flex;
    z-index: 3;
}
.wrap__nav__items__onPhone {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

}
hr{
    color: #191006;
    width: 90%;
}
.navi__item__icon{
    display: none;
}
.hidden{
    display: none;
}
@media(max-width: 1200px){
    .wrap__nav__items{
        display: none;
    }

    .navi__item__icon{
        display: flex;
    }

}
.wrap__nav__items__onPhone{

    padding:15px;
}
.active{
    background: rgba(124, 81, 9, 0.94);
    border-radius: 1rem;
    color: white;
}
.active:hover{
    background: rgba(46, 31, 5, 0.94);
    border-radius: 2rem;
    color: white;
}
