.wrap-houses-info{
    width: 100%;
    height: 500px;
}
.btn:hover{
    border: 1px solid rgb(168, 117, 8)
}
.wrap-houses-info{
    display: flex;
    justify-content: center;
}
.wrap-houses-content{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 60vw;
}

@media (max-width: 500px){
    .wrap-houses-content span{
        font-size: 0.8rem;
        text-align: center;
    }
    .wrap-houses-content h1{
        text-wrap: nowrap;
        text-align: center;
    }

}

.wrap-banner-houseList{
    width: 100%;
    height: 40svh;
    background-image: url('../../images/slider.png');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
}
.wrap-banner-houseList h1{
    filter: drop-shadow(0px 2px 5px rgba(193, 193, 193, 0.68));
    padding: 30px;
    border-radius: 1em;
    border: 2px solid rgb(255, 255, 255);
    background-color: rgba(255, 255, 255);
    z-index:2;
}

p {
    margin-top: 0;
    margin-bottom: 0rem;
}

.col-md-4{
    display: flex;
    justify-content: center;
    align-items: center;
}
.col-md-4 img{
    border-radius: 25%;
}


.wrap-houseList{
    width: 100%;
    height: 100%;
    margin: 5vh 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.card{
    z-index: 2;
    width:550px;
    margin: 10px;
}
#card-body-last{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.wrap__form__perehod-doma{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-color: rgba(182, 145, 56, 0.18);
    height: 100svh;
    width: 100%;
    border-radius: 20px 20px 0 0;
}
#last, #legend{
    margin-bottom: 70px;
}
@media (max-width: 767px){
    .col-md-4 img{
        border-radius: 0.5rem;
    }
    .wrap-text-form{
        transform: scale(60%);
    }
}
.form{
    width: 350px;
    height: 650px;
    border: 2px solid #b18a3c;
    padding: 30px;
    background-color: rgb(255, 255, 255);
    border-radius: .5rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    backdrop-filter: blur(30px);
    filter: drop-shadow(0px 0px 5px #000000);
}
.wrap-text-form{
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    width: 800px;
    height: 400px;

}
.wrap-info{
    width: 600px;
    height: 100px;
    border-bottom: 100px;
}
.wrap-text-form h2{
    font-size:15px;

}
.wrap-text-form h1{
    margin-bottom: 30px;
    font-size: 45px;
}

.wrap-text-form img{
    margin-top: 55px;
    width: 700px;
}
@media (max-width: 1260px){
    .wrap__form__perehod-doma{
        flex-direction: column;
        height: 100%;
        padding: 50px;
        gap: 0;
    }
    .wrap-text-form{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .wrap-text-form img{
        display: none;

    }
    .wrap-text-form h1,.wrap-text-form h2{
        text-align: center;
    }
}

.wrap__form__perehod-doma{
    animation: fade 1s;
}
@keyframes fade {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 100;
    }
}
