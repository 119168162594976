*{
    margin: 0;
    padding: 0;
}
.Banner{
    width: 100%;
    height: 100svh;
}
.wrap__image__banner{
    width: 100%;
    height: 100svh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.Banner__image{
    max-width: 2000px;
    min-width: 1750px;
    width: 100%;
    height: 100svh;
    background: url("../../images/index_main_banner.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
@media(max-width: 700px){
    .Banner__image{
        background: url("../../images/index_main_banner_min.png");
        max-width: 2000px;
        min-width: 1750px;
        width: 100%;
        height: 100svh;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .wrap__image__banner{
        width: 100%;
        height: 115svh;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
}