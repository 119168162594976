
*{
    margin: 0;
    padding: 0;
}
#mainForm  {
    width: 100%;
    height: 95svh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
#forma{
    width: 350px;
    height: 700px;
    border: 2px solid #b18a3c;
    padding: 30px;
    background-color: rgb(255, 255, 255);
    border-radius: .5rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    backdrop-filter: blur(30px);
    filter: drop-shadow(0px 0px 5px #000000);
    position: absolute;
}
.form-control{
    margin: 10px 0;
}
.btn{
    background: #b18a3c;
    color: #fff;
    transition: 0.2s;
}
.btn:hover{
    background: #8f6b0b;
    color: #fff;
}

textarea{
    resize: none;
}
small{
    color: #777777;
    font-size: 0.6em;
}
.wrapp-small{
    text-align: center;
    line-height: 0.5;
}
.contacts{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

h1{
    font-size: 2em;
}
.wrap-forma{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 300px;

}
#imgform{
    height:70vh;
    width: 100%;
    z-index: -1;
}
@media(max-width: 1050px){

    #imgform{
        transform: scale(90%);
        height:300px;
    }
    #mainForm{
        margin: 10vh 0;
    }
}